.welcome-header-element {
  &.welcome-header-background {
    &.with-background {
      background-color: var(--header-background-color);
    }

    &.transparent-background {
      background-color: transparent;
    }
  }
}
