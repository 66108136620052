.header-element {
  &.transparent-header {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .settings-wrapper {
    display: flex;
    align-items: center;
    margin-right: var(--spacing-lg);
    margin-left: auto;
  }

  .help-link {
    color: var(--header-font-color);
  }
}
